<template>
<div class="inner">
    <div class="page_title">
        <div class="container">Contact us</div>
    </div>
    <div class="container">
        <a-row :gutter="10">
            <a-col :span="12" v-for="(contact, idx) in contacts" :key="idx"> 
                <div class="box flex"> 
                    <div class="flex_column">
                        <span class="heading">{{contact.heading}}</span>
                        <span class="sub_heading">{{contact.sub_heading}}</span>
                        <span class="action">
                            <component :is="$antIcons['MobileOutlined']" />
                            {{contact.phone}}
                        </span>
                        <span class="action">
                            <component :is="$antIcons['WhatsAppOutlined']" />
                            {{contact.tel}}
                        </span>
                        <span class="action">
                            <component :is="$antIcons['MailOutlined']" />
                            {{contact.email}}
                        </span>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</div>
</template>
<script>
import { getContact  } from '@/apis/home/contact'
export default {
    data(){
        return {
            contacts: []
        }
    },
    methods: {
        async getContactHandler(){
            const res = await getContact()
            this.contacts = res.list
        }
    },
    created(){
        this.getContactHandler()
    }
}
</script>
<style lang="stylus" scoped>
.box
    margin 20px 0
.icon
    width 120px
    display block
    margin 10px auto
.heading
    font-size 26px
    margin-bottom 10px
.sub_heading
    font-size 14px
    color #abb1bf
    margin-bottom 10px
.flex_column
    justify-content center
.action
    font-size 18px
    padding 5px 0
</style>